@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.body {
  font-family: 'Bebas Neue', sans-serif;
}

.neon-text {
  color: #cc3700; /* Darker orangered color */
  font-family: 'Bebas Neue', cursive;
  text-transform: uppercase;
  position: relative;
  padding: 10px;
  text-align: center;
  font-size: 6rem;
}

.neon-text {
  font-size: 6rem;
  transform: translateX(-2%);
}

@media (min-width: 640px) {
  .neon-text {
    font-size: 8rem; /* Larger font size for desktop */
    margin-top: -0.5rem; /* Adjust margin to bring text closer to logo */
  }
}

@media (min-width: 640px) {
  .logo-img {
    /* margin-right: 0; */
    /* margin-left: 15rem; */
    transform: translateX(-1%);
  }
}

@media (min-width: 640px) {
  .neon-text {
    font-size: 10rem;
    margin-left: 0;
    transform: translateX(-40%);
  }
}

.neon-text::before,
.neon-text::after {
  content: 'TYPEMUSE';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #cc3700; /* Darker orangered color */
  z-index: -1;
}

.neon-text::before {
  filter: blur(2px);
}

.neon-text::after {
  filter: blur(20px);
  opacity: 0.9;
}

.neon-text::before,
.neon-text::after {
  text-shadow:
    0 0 10px #ff4500,
    /* Original orangered for glow */ 0 0 20px #ff4500,
    0 0 30px #ff4500,
    0 0 40px #ff4500,
    0 0 50px #ff4500,
    0 0 75px #ff4500;
}

.text-orangered {
  color: #ff4500;
}

.logo-img {
  /* height: auto; */
  /* max-width: 50%;  */
  height: 200px;
  width: 1200px;
  padding-left: 5rem;
  padding-bottom: 1rem;
}
